<template>
    <delivery-points-by-day></delivery-points-by-day>
</template>

<script>
import DeliveryPointsByDay from '../../components/deliverypoints/DeliveryPointsByDay.vue'
export default {
  components: { DeliveryPointsByDay },
    
}
</script>