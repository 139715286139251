<template>
  <v-container>
    <v-card
      v-for="(deliveryPoint, key) in deliveryPoints"
      :key="key"
      class="mt-2"
    >
      <v-card-title class="headline">
        {{ deliveryPoint.address }}
      </v-card-title>
      <v-card-subtitle class="headline">
        {{ deliveryPoint.description }}
      </v-card-subtitle>
      <v-card-text>
        <v-chip
          v-for="(newspaper, key2) in deliveryPoint.newspapers"
          v-bind:key="key2"
          :color="newspaper.props.color"
          text-color="white"
          label
          class="mt-3 mr-1 headline"
        >
          <v-avatar left color="white">
            <span :class="newspaper.props.color + '--text'">{{
              newspaper.quantity
            }}</span>
          </v-avatar>
          {{ newspaper.name }}
        </v-chip>
      </v-card-text>
    </v-card>
    <v-card v-if="Object.keys(summary).length > 0" class="mt-3">
      <v-card-title class="headline">
        Resumen:
      </v-card-title>
      <v-card-text>
        <v-chip
          v-for="(value, idx) in summary"
          :key="idx"
          class="ma-1 subtitle-1"
        >
          <v-avatar left color="white">
            <span class="black--text">
              {{ value }}
            </span>
          </v-avatar>
          {{ idx }}
        </v-chip>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "DeliveryPointsByName",
  data: () => ({
    summary: {},
  }),
  computed: {
    ...mapState(["deliveryPoints"]),
  },
  methods: {
    ...mapActions(["getDeliveryPointsByDay"]),
  },
  async mounted() {
    console.log("DeliveryPointsByDay mounted()");
    try {
      await this.getDeliveryPointsByDay(new Date().getDay());
      // getSummary
      if (this.deliveryPoints.length) {
        this.deliveryPoints.forEach((deliveryPoint) => {
          deliveryPoint.newspapers.forEach((newspaper) => {
            this.$set(
              this.summary,
              newspaper.name,
              isNaN(this.summary[newspaper.name])
                ? newspaper.quantity
                : this.summary[newspaper.name] + newspaper.quantity
            );
          });
        });
        console.log(this.summary);
      }
    } catch (error) {
      console.log(error);
      // TO-DO: handle error
    }
  },
};
</script>
